import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import './EmailListForm.style.scss';

const EmailListForm = () => {

  const [responseMsg, setResponseMsg]  = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    addToMailchimp(email)
      .then((data) => {
        setResponseMsg(data.msg)
      })
      .catch((error) => {
        // Errors in here are client side
        // Mailchimp always returns a 200
        console.log(error)
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <form onSubmit={handleSubmit} className="EmailListForm">
      <div class="container text-center EmailListForm__container">
        <h2>Subscribe to my email list!</h2>
        <div className="EmailListForm__wrapper mb-4">
          <input
            placeholder="Email address"
            name="email"
            type="text"
            onChange={handleEmailChange}
          />
          <button type="submit">Subscribe</button>
        </div>
        {responseMsg && <p dangerouslySetInnerHTML={{__html: responseMsg}} />}
      </div>
    </form>
  );
};

export default EmailListForm;