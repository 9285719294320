import React from 'react'
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import VideoArea from '../containers/index-infotechno/video-area'
import CaseStudyArea from '../containers/index-infotechno/case-study-area'
import MailchimSignup from '../components/mailchim-signup'

const ButtonPage = ({ pageContext, location }) => {
    return (
        <Layout location={location}>
            <SEO title="Portfilio" />
            <Header/>
            <PageHeader 
                pageContext={pageContext} 
                location={location}
                title="Portfolio"
            />
            <main className="site-wrapper-reveal">
                <CaseStudyArea amount={7} />
                <VideoArea />
            </main>
            <MailchimSignup />
            <Footer/>
        </Layout>
    )
}

export default ButtonPage
 